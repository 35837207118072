export const environment = {
  production: true,
  // server_url: 'https://iagilitybackend-fl5ady5h6q-uc.a.run.app',
  // server_url: 'http://35.190.41.205',
  // angular_url: 'http://34.120.181.103/',
  // account_url:'http://34.120.181.103/',
  // admin_url:'http://35.201.95.57/',
  // consultant_url:'http://34.117.136.160/',
  // client_url:'http://34.98.78.213/'

  /**Live Version */
  server_url: 'https://server.iagility.com',
  angular_url: 'https://account.iagility.com/',
  account_url:'https://account.iagility.com/',
  admin_url:'https://admin.iagility.com/',
  consultant_url:'https://consultant.iagility.com/',
  client_url:'https://client.iagility.com/',
  main_url: 'https://iagility.com/',


  /**Dev Envirnoment */
  // server_url: 'https://devserver.iagility.com',
  // angular_url: 'http://34.120.181.103/',
  // account_url: 'https://accountdev.iagility.com',
  // admin_url: 'https://admindev.iagility.com',
  // consultant_url: 'https://consultantdev.iagility.com',
  // client_url: 'https://clientdev.iagility.com',
  // main_url: 'http://34.120.210.33/',
};